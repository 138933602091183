let requireModule = require.context(
  '../assets/home/zs',
  false,
  /\.png$/,
);
// console.log('len:', requireModule.keys().length, requireModule.keys());
// console.log(requireModule.keys())
let logos = [];
for (let i = 0; i < requireModule.keys().length; i++) {
  logos.push(
    `${requireModule.keys()[i].split('./')[1].slice(0, -4)}`,
  );
}
// console.log(logos)
 
export default logos;